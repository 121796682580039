const Instances = {
    InitializeVueInstances: function (query, fa) {
        const
            elements = document.querySelectorAll(query),
            fn = window[fa[0]][fa[1]];

        elements.forEach(elem => {
            if(typeof fn === 'function') {
                fn(elem);
            }
        });
    },
    Init: function () {
        Instances.InitializeVueInstances('.form:not(.form--custom)', ['FormInstance', 'Init']);
    }
};

Barba.Dispatcher.on("transitionCompleted", function () {
    Instances.Init();
});
