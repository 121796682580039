Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("header")) {
        const header = new Vue({
            el: '#header',
            store,
            data: function () {
                return {
                    scrolled: false
                }
            },
            computed: {
                menuExpanded: {
                    get: function () {
                        return this.$store.getters.menuExpanded
                    },
                    set: function (newValue) {
                        this.$store.dispatch('toggleMenu', newValue)
                    }
                }
            },
            watch: {
                menuExpanded: function () {
                    document.body.classList.toggle('body--overflowHidden');
                }
            },
            mounted: function () {
                let that = this;
                this.resetExpandedMenu();

                window.addEventListener('scroll', function () {
                    that.watchScrollPosition();
                });
            },
            methods: {
                resetExpandedMenu: function () {
                    this.menuExpanded = false;
                    document.body.classList.remove('body--overflowHidden');
                },
                watchScrollPosition() {
                    let that = this;
                    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

                    that.scrolled = scrollTop > 50;
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});